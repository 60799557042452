import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/DefaultNewsLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Thank you to #humanappeal for your generous donations for our Service Users & their families.`}</h1>
    <p>{`@ShantiAid`}</p>
    <p><em parentName="p">{`we really appreciate their kind gesture`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "626px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/aa01354302fb481ffaf2d4352a7042f8/b28e6/shanti-food-donation-items.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "116.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAXABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDAv/EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABr2izl7Vsbi0MrBf/xAAcEAACAgIDAAAAAAAAAAAAAAABAgARAxITMTL/2gAIAQEAAQUCQKsNUOURfeIvtAQU7O8//8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQERL/2gAIAQMBAT8BpmVP/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAEREhP/2gAIAQIBAT8Bp0ZWmaP/xAAfEAACAgEEAwAAAAAAAAAAAAAAAREhAhASImExgZH/2gAIAQEABj8CjF/TlA48SNql2XpOJutPotP0f//EABsQAAMBAQEBAQAAAAAAAAAAAAABESFBMWGB/9oACAEBAAE/IbDn66JquOivlpmtNQ4eCQlovB2qSOAoFzV9Yf/aAAwDAQACAAMAAAAQmCC+/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARUf/aAAgBAwEBPxDQUIgNLDl//8QAGREAAgMBAAAAAAAAAAAAAAAAAAERIUEx/9oACAECAQE/EFCn0YOpwm8P/8QAHhABAQACAgIDAAAAAAAAAAAAAREAITFBUWFxkaH/2gAIAQEAAT8QbQEs0rwBAja4H1chG1kcdfGNlCoguxj+XPehUUAeNOQC9+cjgG+SOO0bHRvdKPOCDBM1H0eM/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Human Appeal",
            "title": "Human Appeal",
            "src": "/static/aa01354302fb481ffaf2d4352a7042f8/b28e6/shanti-food-donation-items.jpg",
            "srcSet": ["/static/aa01354302fb481ffaf2d4352a7042f8/e07e9/shanti-food-donation-items.jpg 200w", "/static/aa01354302fb481ffaf2d4352a7042f8/066f9/shanti-food-donation-items.jpg 400w", "/static/aa01354302fb481ffaf2d4352a7042f8/b28e6/shanti-food-donation-items.jpg 626w"],
            "sizes": "(max-width: 626px) 100vw, 626px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      